/* @font-face {
  font-family: 'Microsoft YaHei';
  src: url('/src/font/MicrosoftYaHei/web/font/MicrosoftYaHei.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
} */

.main {
  width: 100%;
  max-width: 600px; 
  margin: 0 auto;
  background-color: #ffffff;
  padding: 20px;
  box-sizing: border-box;
}

.dropzone {
  position: relative;
  background-color: #ddeaff;
  border: none;
  padding: 0;
  width: 100%;
  height: 300px; 
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.upload-image {
  width: 50px;
  height: 50px;
  margin-top: 30px;
  cursor: pointer;
}

.file-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.file-preview img {
  display: block;
  width: 100%;
  height: 100%;
  /* object-position: center; */
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}
.cancel-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  width: 20px;
  height: 20px;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-photo-title {
  font-size: 16px;
  margin: 10px 0;
  font-weight: 700;
  color: #333333;
}

.upload-instructions {
  font-size: 12px;
  color: #666666;
  margin-top: 5px;
  text-align: center;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  box-sizing: border-box;
  margin-left: 10px;
}

.input-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.label-row {
  display: flex;
  align-items: center;
}

label {
  display: flex;
  align-items: center;
  text-align: left;
  color: #222222;
  font-weight: bold;
  font-size: 14px;
}

label img {
  margin-right: 7px;
  margin-bottom: 3px;
  width: 18px;
  height: 18px;
}

.input-wrapper {
  display: flex;
  align-items: center;
  margin: 5px 0 10px 0;
  position: relative;
  width: 100%;
}

.input-field {
  padding: 10px;
  font-size: 16px;
  font-family: 'Microsoft YaHei', sans-serif;
  border: none;
  width: 100%;
  background: #F3F8FF;
  border-radius: 5px;
  color: #222222;
  box-sizing: border-box;
}

.clear-button {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.remove-added-input-button {
  background: none;
  border: none;
  cursor: pointer;
}

.remove-added-input-button img {
  width: 13px;
  height: 13px;
  margin-top: 5px;
  margin-left: 3px;
}
.clear-button img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.input-field:focus {
  outline: none;
  border: 1px solid #F3F8FF;
}

.textarea-group textarea {
  width: 100%;
  height: 150px; 
  padding: 10px;
  background-color: #F3F8FF;
  border: none;
  border-radius: 5px;
  color: #222222;
  margin-top: 5px;
  font-family: 'Microsoft YaHei', sans-serif;
  line-height: 26px;
  font-style: normal;
  font-size: 16px;
  box-sizing: border-box;
}

textarea:focus {
  outline: none;
  border: 1px solid #F3F8FF;
}

.description {
  margin: 0;
  font-size: 12px;
  font-family: 'Microsoft YaHei', sans-serif;
  color: #5D6B76;
  line-height: 18px;
  text-align: left;
  font-style: normal;
}

.clear-button:hover {
  color: #333;
}

.input-all {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}
.add-input-button img{
  width: 25px;
  height: 25px;
  margin-left: 10px;
  margin-bottom: 3px;
  border-radius: 5px;
  cursor: pointer;
}

.added-inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 5px 0 10px 0;
  width: 100%;
  box-sizing: border-box;
  margin-left: 10px;

}

.added-input {
  display: flex;
  align-items: center;
  padding: 2px 5px;
  background-color: #F3F8FF;
  border-radius: 5px;
}

.added-input-img {
  border: 1px dashed #A1ACB4;
  margin-top: 1px;
  width: 10px;
  height: 10px;
}

.added-input span {
  margin-left: 5px;
  font-size: 12px;
  font-weight: 700;
  color: #444242;
  line-height: 26px;
  text-align: left;
  font-style: normal;
}


.textarea-group {
  margin-bottom: 20px;
  width: 98%;
  box-sizing: border-box;
  margin-left: 10px;
}

.discover-button {
  background: linear-gradient(180deg, #6EFFF1 0%, #0B68FE 100%);
  color: white;
  padding: 8px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 70%;
  margin: 20px auto;
  font-weight: bold;
  display: block;
}

@media (max-width: 768px) {
  .main {
    padding: 10px;
  }

  .dropzone {
    height: 250px; 
    width: 95%;
    margin-left: 10px;
  }

  .upload-image {
    margin-top: 20px;
    width: 40px;
    height: 40px;
  }

  .textarea-group textarea {
    height: 100px; 
    width: 95%;
  }

  .discover-button {
    width: 90%;
  }
}
.loading-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-content {
  text-align: center;
}

.loading-spinner {
  width: 20px;
  height: 20px;
  border: 4px dashed rgb(255, 255, 255);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
  margin-left: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



.loading-content p {
  color: #fff;
  margin-top: 10px;
  font-size: 16px;
 
}
.upload-instructions.mobile {
  display: none;
}

@media (max-width: 768px) {
  .upload-instructions.desktop {
    display: none;
  }

  .upload-instructions.mobile {
    display: block;
  }
}