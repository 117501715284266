.cookie-banner {
  position: fixed;
  bottom: 0;
  width: 98%;
  background: transparent;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  flex-wrap: wrap;
  overflow: hidden;
}

.cookie-banner-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.cookie-banner-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 90%;
  z-index: 1;
  font-size: 14px;
}

.cookie-banner-content p {
  margin: 0;
}

.cookie-banner-content a {
  color: #0b68fe;
  text-decoration: underline;
}

.cookie-banner-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.cookie-banner-buttons .allow-btn button,
.cookie-banner-buttons .decline-btn button {
  padding: 10px 40px;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  text-align: center;
}

.cookie-banner-buttons .allow-btn button {
  background-color: #0b68fe;
  color: white;
}

.cookie-banner-buttons .allow-btn button:hover {
  background-color: #0a5cd8;
}

.cookie-banner-buttons .decline-btn button {
  background-color: white;
  color: #0b68fe;
}

.cookie-banner-buttons .decline-btn button:hover {
  background-color: #e6f0ff;
}

.cookie-banner-close {
  position: absolute;
  top: 15%;
  right: 3%;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 2;
}

.cookie-banner-close img {
  width: 15px;
  height: 15px;
}

@media (max-width: 600px) {
  .cookie-banner {
    padding: 20px 10px 20px 15px;
  }
  .cookie-banner-buttons {
    width: 100%;
  }

  .cookie-banner-content {
    max-width: calc(100% - 8%);
    padding-right: 0px;
  }

  .cookie-banner-close {
    width: 20px;
    height: 20px;
    top: 15%;
    right: 7%;
  }
}

@media (max-width: 400px) {
  .cookie-banner {
    padding: 20px 10px;
  }
  .cookie-banner-buttons {
    width: 150%;
   justify-content: flex-start;
  }

  .cookie-banner-content {
    max-width: calc(100% - 8%);
    padding-right: 0px;
  }

  .cookie-banner-close {
    width: 20px;
    height: 20px;
    top: 15%;
    right: 6%;
  }
}
