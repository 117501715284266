.footer {
  background-color: #f0f0f0;
  padding: 12px;
  font-family: MicrosoftYaHei;
  font-size: 10px;
  color: #222222;
  line-height: 19px;
  text-align: center;
  font-style: normal;
}

.footer p {
  margin: 5px 0;
}

.footer-divider {
  border: none;
  border-top: 1px solid #e2e2e2;
  margin: 0;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.footer a {
  text-decoration: none; 
  cursor: pointer; 
  color: #222222;
}

.footer-separator {
  margin: 0 4px; 
}

