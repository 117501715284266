.result-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 150px); 
}

.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
}

.image-container {
  width: 100%;
  max-width: 600px;
  max-height: 300px; 
  overflow: hidden; 
  border-radius: 10px;
}

.uploaded-image {
  width: 100%;
  height: auto;
  object-fit: contain; 
  border-radius: 10px;
}

.inter-card{
  background-color: #f3f8ff;
  border-radius: 8px;
  margin-top:20px;
  padding-bottom: 50px;
}
.interpretation{
  margin-left: 15px;
}
.interpretation-container,
.locations-container {
  width: 100%;
  max-width: 600px; 
}
.locations-container {
  display: flex;
  flex-direction: column;
}
.location-card {
  background-color: #f3f8ff;
  border-radius: 8px;
  padding: 0px 25px;
  margin-top: 20px;
  padding-bottom: 50px;
}

.location-card-content {
  display: flex;
  align-items: center;
}

.location-image-container {
  margin-right: 10px; 
}
.detail{
  margin-left: 30px;
  margin-bottom: -13px;
}
.count{
  margin-bottom: -13px;
}
.location-image-container img {
  width: 20px;
  height: 20px;
  margin-bottom: -25px;
  border: 1px dashed #A1ACB4;
}

.location-details {
  flex: 1;
}
.location-details-row {
  display: flex;
  align-items: center;
}




@media (max-width: 768px) {
  .content-wrapper {
    padding: 10px;
  }
}
