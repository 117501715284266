.header {
  position: relative;
  color: white;
  overflow: hidden;
}

.header-image {
  width: 100%;
  height: 320px;
  max-height: 100vh;
  object-fit: cover;
  transform: translateX(-10px);
  transition: height 0.3s ease-in-out;
}

.header-content {
  position: absolute;
  top: 40%;
  left: 40%;
  transform: translateY(-40%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  transition: all 0.3s ease-in-out;
  font-family: 'Microsoft YaHei', sans-serif;
}

.logo-image {
  width: 80px; 
  margin-bottom: 10px; 
  position: relative;
  left: 20%; 
}

.title {
  font-size: 4em;
  margin: 5px 0; 
  transition: all 0.3s ease-in-out;
}

.subtitle {
  width: 70%;
  font-family: 'MicrosoftYaHei', sans-serif;
  font-size: 1.5em;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 1.5;
  text-align: justify;
  font-style: normal;
  margin-top: 10px;
  transition: all 0.3s ease-in-out;
  word-spacing: 0; 
  letter-spacing: -1px; 
  white-space: pre-line;
}

@media (max-width: 2024px) {
  .header-content {
    top: 35%;
    transform: translateY(-35%);
    left: 40%;
  }

  .logo-image {
    width: 60px; 
    left: 15%; 
  }

  .title {
    font-size: 3em;
  }

  .subtitle {
    width: 80%;
    font-size: 1.2em;
    white-space: pre-line;
  }
}

@media (max-width: 1024px) {
  .header-content {
    top: 35%;
    transform: translateY(-35%);
    left: 33%;
  }

  .logo-image {
    width: 60px; 
    left: 15%; 
  }

  .title {
    font-size: 3em;
  }

  .subtitle {
    width: 80%;
    font-size: 1.2em;
    white-space: pre-line;
  }
}

@media (max-width: 968px) {
  .header-image {
    height: 250px;
    object-position: 33%;
  }

  .header-content {
    top: 30%;
    transform: translateY(-30%);
    left: 30%;
    align-items: center;
  }

  .logo-image {
    width: 50px; 
    left: 0%;
  }

  .title {
    font-size: 2em;
  }

  .subtitle {
    width: 75%;
    font-size: 1em;
    white-space: pre-line;
  }
}

@media (max-width: 480px) {
  .header-image {
    height: 200px;
    object-position: 35%;
  }

  .header-content {
    top: 25%;
    transform: translateY(-25%);
    left: 35%;
    align-items: center;
  }

  .logo-image {
    width: 40px; 
    left: 0%;
  }

  .title {
    font-size: 1.5em;
  }

  .subtitle {
    width: 90%;
    font-size: 0.9em;
    white-space: pre-line;
  }
}
