.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: MicrosoftYaHei;
  }
  
  .about-content {
    background-color: #f3f8ff;
    padding: 20px;
    border-radius: 10px;
  }
  
  .about-content h1,
  .about-content h2 {
    color: #000;
  }
  
  .about-content p {
    color: #000;
    line-height: 1.6;
    margin-bottom: 20px;
    font-size: 16px;
  }
  
  .about-content ul,
  .about-content ol {
    margin: 20px 0;
    padding-left: 20px;
  }
  
  .about-content ul li,
  .about-content ol li {
    margin-bottom: 10px;
    color: #000;
    font-size: 16px;
  }
  